type Rails = {
  env: string
  user?: Window['GA']['rails']['user']
  locale: string
  phrases: Record<string, string>
  onesignalAppId?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { env, user, locale, phrases, onesignalAppId } = (window as any).GA.rails as Rails

const isProduction = (): boolean => env === 'production'
const isStaging = (): boolean => env === 'staging'
const isDevelopment = (): boolean => env === 'development'
const isTest = (): boolean => env === 'test'
const isUserSignedIn = (): boolean => !!user

export { env, user, locale, onesignalAppId, phrases, isProduction, isStaging, isDevelopment, isTest, isUserSignedIn }
