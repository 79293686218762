import { isUserSignedIn } from '../../shared/lib/rails'

// NOTE: 実際のバナー表示は別でやっているが、ログイン前画面のインストールバナー抑制のために常にイベント設定している
export default function () {
  window.addEventListener('beforeinstallprompt', (event) => {
    if (!isUserSignedIn()) {
      // NOTE: Prevent Chrome <= 67 from automatically showing the prompt
      // https://developers-jp.googleblog.com/2018/06/chrome-68-beta-add-to-home-screen.html
      event.preventDefault()
      return false
    }
    return true
  })
}
