const privateData = new WeakMap()

export class LuminousLightbox extends HTMLElement {
  connectedCallback(): void {
    setTimeout(() => this.init(), 0)
  }

  disconnectedCallback(): void {
    const luminous = privateData.get(this)
    luminous?.destroy()
  }

  async init(): Promise<void> {
    const { Luminous } = await import('luminous-lightbox')
    const luminous = new Luminous(this.querySelector('a'))
    privateData.set(this, luminous)
  }
}

declare global {
  interface Window {
    LuminousLightbox: typeof LuminousLightbox
  }
}

if (!window.customElements.get('luminous-lightbox')) {
  window.LuminousLightbox = LuminousLightbox
  window.customElements.define('luminous-lightbox', LuminousLightbox)
}
