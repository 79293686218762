import '@github/relative-time-element'
import { start } from '@sonicgarden/rails-ujs-compat'
import 'bootstrap'
import '@/src/application/lib'
import '@/src/shared/pages/base'
import '@/src/customElements/AutoAnimate'
import '@/src/customElements/ImageCompression'
import '@/src/customElements/ConfirmationRequired'
import '@/src/customElements/CopyButton'
import '@/src/customElements/PdfPrintButton'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/bs-tooltip-element'
import { cssHasPseudoPolyfill } from '@/src/lib/cssHasPseudo'
import { startTurbo } from '@/src/lib/turbo'

cssHasPseudoPolyfill()
start()
startTurbo()
