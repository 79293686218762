import { polyglot } from './polyglot'
import { isTest, user, onesignalAppId } from '@/src/shared/lib/rails'

const PROMPT_OPTIONS = {
  actionMessage: polyglot.t('js.onesignal.action_message'),
  acceptButtonText: polyglot.t('js.onesignal.accept_button_text'),
  cancelButtonText: polyglot.t('js.onesignal.cancel_button_text'),
  customlink: {
    enabled: true,
    style: 'button',
    size: 'medium',
    text: {
      subscribe: polyglot.t('js.onesignal.subscribe_text'),
      unsubscribe: polyglot.t('js.onesignal.unsubscribe_text'),
      explanation: polyglot.t('js.onesignal.explanation_text'),
    },
    unsubscribeEnabled: true,
  },
}

// TODO: 未ログインの場合の通知ダイアログ非表示を意図通り動作するように対応してこちらの条件は消したい
const IGNORE_PATHNAMES = new Set(['/simulation'])

export const initOnesignal = (): void => {
  if (
    isTest() ||
    !onesignalAppId ||
    window.location.protocol === 'http:' ||
    IGNORE_PATHNAMES.has(window.location.pathname)
  ) {
    return
  }

  const script = document.createElement('script')
  script.src = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js'
  script.defer = true
  document.head.append(script)

  window.OneSignalDeferred = window.OneSignalDeferred || []

  // SEE: https://documentation.onesignal.com/docs/web-sdk-reference
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.OneSignalDeferred.push(async (OneSignal: any) => {
    await OneSignal.init({
      appId: onesignalAppId,
      autoPrompt: false,
      promptOptions: PROMPT_OPTIONS,
      notifyButton: {
        enable: true,
        displayPredicate: () => false,
      },
    })

    if (user) {
      OneSignal.Slidedown.promptPush()
    }

    if (user) {
      OneSignal.User.addTags(user.onesignalTags)
    } else {
      OneSignal.User.getTags((tags: Record<string, string>) => {
        OneSignal.User.removeTags(Object.keys(tags))
      })
    }
  })
}
