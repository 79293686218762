export class PdfPrintButton extends HTMLElement {
  // NOTE: スマホアプリ（expo）の場合はプリント処理を差し替えられるようにしている
  static print(win: Window): void {
    win.print()
  }

  connectedCallback(): void {
    requestAnimationFrame(() => {
      this.button.addEventListener('click', this.onClick)
    })
  }

  disconnectedCallback(): void {
    this.button.removeEventListener('click', this.onClick)
  }

  get button(): HTMLButtonElement {
    const element = this.querySelector('button')
    if (!element) throw new Error('button element not found')
    return element
  }

  get iframe(): HTMLIFrameElement | undefined {
    return this.querySelector('iframe') ?? undefined
  }

  onClick = (): void => {
    const iframe = this.iframe
    const win = iframe?.contentWindow ?? window
    PdfPrintButton.print(win)
  }
}

declare global {
  interface Window {
    PdfPrintButton: typeof PdfPrintButton
  }
}

if (!window.customElements.get('pdf-print-button')) {
  window.PdfPrintButton = PdfPrintButton
  window.customElements.define('pdf-print-button', PdfPrintButton)
}
