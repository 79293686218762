import { set } from 'badgin'
import { user } from '@/src/shared/lib/rails'

export function setBadgin(): void {
  if (!user) return

  const { unreadNotificationsCount, unreadTalkMessagesCount } = user
  const unreadCount = unreadNotificationsCount + unreadTalkMessagesCount

  if (unreadCount < 1) return

  try {
    set(unreadCount, {
      favicon: {
        backgroundColor: '#F17685',
      },
    })
  } catch {
    // NOTE: mobile safariでwindow.matchMediaでundefinedが返るケースがあるための暫定対応
    // SEE: https://github.com/jaulz/badgin/blob/764c2aa7c372cbc883bd929f35db96350827fe85/src/favicon.ts#L125
  }
}
