import type { JsonObject } from 'type-fest'
import { PdfPrintButton } from '@/src/customElements/PdfPrintButton'
import { delegateEvent } from '@/src/lib/delegateEvent'
import { user } from '@/src/shared/lib/rails'

const postMessage = (type: string, payload: JsonObject) => {
  const data = { type, payload }
  console.debug('postMessage', data)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(data))
}

const convertRelativeUrlToAbsolute = (url: string): string => {
  return url.startsWith('/') ? `${location.origin}${url}` : url
}

/** PDF出力用のHTMLを取得する */
const getPrintHtml = (win: Window): string => {
  const htmlElement = win.document.querySelector('html')
  if (!htmlElement) throw new Error('html element not found')

  const clonedElement = htmlElement.cloneNode(true) as HTMLElement

  // html内の画像とcssの相対パスを絶対パスに変換する（baseタグだと上手く動作しないケースがあった）
  for (const img of clonedElement.querySelectorAll('img')) {
    const src = img.src
    if (!src) continue

    img.src = convertRelativeUrlToAbsolute(src)
  }
  for (const link of clonedElement.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]')) {
    const href = link.href
    if (!href) continue

    link.href = convertRelativeUrlToAbsolute(href)
  }

  return clonedElement.outerHTML
}

const initExpo = () => {
  document.querySelector('html')!.classList.add('expo')

  // NOTE: アプリの場合はwindow.printが使えないので印刷処理を差し替えている
  PdfPrintButton.print = (win: Window): void => {
    postMessage('print', { html: getPrintHtml(win), filename: `${document.title}.pdf` })
  }

  delegateEvent<HTMLAnchorElement>('click', 'a[data-expo-browser]', ({ event, currentTarget }) => {
    event.preventDefault()
    const url = currentTarget.href

    postMessage('openBrowser', { url })
  })

  delegateEvent<HTMLAnchorElement>('click', 'a[download],a[data-expo-download]', ({ event, currentTarget }) => {
    event.preventDefault()
    const url = currentTarget.href
    // NOTE: download属性は存在しない場合undefinedではなく空文字になる
    const filename = currentTarget.dataset.expoDownload ?? currentTarget.download

    postMessage('download', { url, filename })
  })

  delegateEvent<HTMLButtonElement>('click', 'button[data-expo-share]', ({ event, currentTarget }) => {
    event.preventDefault()
    const message = currentTarget.dataset.message
    if (!message) return

    postMessage('share', { message })
  })

  if (user) {
    const { unreadNotificationsCount, unreadTalkMessagesCount } = user
    postMessage('init', { unreadNotificationsCount, unreadTalkMessagesCount })
  }
}

export const startExpo = (): void => {
  let loaded = false
  // NOTE: イベント実行順序的にアプリ側からのロード完了通知を受け取ってから初期化する必要がある
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.addEventListener('message', ({ data }: any) => {
    try {
      const { type } = JSON.parse(data)
      if (type === 'init_expo') {
        // NOTE: expo側から2回呼ばれることがあるための対策
        if (loaded) return

        loaded = true
        initExpo()
      }
    } catch {
      // NOTE: window.postMessageは他でも利用されているのでパース出来ないケースがあるのは仕方ない
    }
  })
}
