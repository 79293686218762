/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: テストしやすくする為
const MAX_VIDEO_DURATION = import.meta.env.RAILS_ENV === 'test' ? 3 : 300

const enableVideoLinks = (): void => {
  // NOTE: 動画を見終わるまで有効にならないリンクやボタン
  for (const element of document.querySelectorAll<HTMLAnchorElement>('a.js-video-ended')) {
    element.classList.remove('disabled')
  }

  for (const element of document.querySelectorAll<HTMLButtonElement>('button.js-video-ended')) {
    element.disabled = false
  }
}

// @ts-expect-error TS7031
const onReady = ({ target }): void => {
  setTimeout(enableVideoLinks, target.getDuration() * 1000)
}

// @ts-expect-error TS7006
const init = (element): void => {
  const script = document.createElement('script')
  script.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.querySelectorAll('script')[0]
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  firstScriptTag!.parentNode!.insertBefore(script, firstScriptTag)
  ;(window as any).onYouTubeIframeAPIReady = (): any =>
    new (window as any).YT.Player(element, {
      videoId: element.dataset.videoId,
      width: '100%',
      height: '100%',
      // NOTE: https://stackoverflow.com/a/47724503
      host: 'https://www.youtube.com',
      playerVars: {
        controls: 0,
        modestbranding: 1,
        rel: 0,
        iv_load_policy: 3,
      },
      events: { onReady },
    })

  // NOTE: 端末によってはyoutube iframe apiが上手く動作しないことがあるようなので、最大時間を過ぎたら必ず進めるようにしている。
  setTimeout(enableVideoLinks, MAX_VIDEO_DURATION * 1000)
}

export function setupYoutubeEnded(): void {
  const element = document.querySelector('[data-video-id]')
  if (element) {
    init(element)
  }
}
